<template>
  <div class="v-my-prefer">
    <base-layout>
      <n-form :label-width="80" :model="model" ref="formRef" label-placement="top">
        <n-grid :cols="12" :x-gap="20">
          <n-form-item-gi :span="gridColSetting.defaultDownloadExt"
           label="預設下載格式" path="defaultDownloadExt">
            <n-radio-group v-model:value="form.defaultDownloadExt" name="radiogroup1">
              <n-space>
                <n-radio value="MP3">MP3</n-radio>
                <!-- <n-radio value="WAV">WAV</n-radio> -->
              </n-space>
            </n-radio-group>
          </n-form-item-gi>
          <n-form-item-gi :span="gridColSetting.searchPrefer" label="搜尋結果偏好" path="searchPrefer">
            <n-checkbox-group v-model:value="form.searchPrefer">
              <n-space item-style="display: flex;">
                <n-checkbox value="Waves" label="Waves" />
                <n-checkbox value="Description" label="Description" />
                <n-checkbox value="Tags" label="Tags" />
              </n-space>
            </n-checkbox-group>
          </n-form-item-gi>
        </n-grid>
      </n-form>

      <div class="btn-wrap">
        <mi-button>Save</mi-button>
      </div>
    </base-layout>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue';
import {
  NForm, NFormItemGi, NGrid, NSpace, NRadio, NCheckbox, NCheckboxGroup,
} from 'naive-ui';
import MiButton from '@/components/Global/MiButton.vue';
import BaseLayout from '@/components/MyProfile/BaseLayout.vue';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'MyPrefer',
  components: {
    MiButton,
    BaseLayout,
    NForm,
    NFormItemGi,
    NGrid,
    NSpace,
    NRadio,
    NCheckbox,
    NCheckboxGroup,
  },
  setup() {
    const siteStore = useSiteStore();

    const gridColSetting = computed(() => {
      if (siteStore.deviceType.includes('tablet')) {
        return {
          defaultDownloadExt: 6,
          searchPrefer: 6,
        };
      }

      return {

        defaultDownloadExt: 12,
        searchPrefer: 12,
      };
    });

    return {
      gridColSetting,
      form: ref({
        defaultDownloadExt: '',
        searchPrefer: '',
      }),
    };
  },
});
</script>
<style lang="scss" scoped>
.btn-wrap {
  @include flex(center);
  margin-top: 65px;
}

@media screen and (min-width: $tablet) {
  .btn-wrap {
    @include flex(flex-end);
    margin-top: 65px;
  }
}
</style>
